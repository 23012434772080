import {
    handleQueryResolve
} from '../utils';

export default function(){
    return this.query(`
        SELECT
            GenericName,
            Strength,
            SUM(
                CASE WHEN QtyNon = -9999999 THEN 0 ELSE QtyNon END
            ) AS Stock
        FROM Pharmacy.dbo.DrugPack
            INNER JOIN Pharmacy.dbo.DrugRoot ON[DrugRoot].DIN = DrugPack.DINx
        WHERE
        GenericName LIKE('%TRAMADOL%')
            OR GenericName LIKE('%ALENDRONATE%')
            OR GenericName LIKE('%Alprazolam%')
            OR GenericName LIKE('%Amlodipine%')
            OR GenericName LIKE('%Anastrozole%')
            OR GenericName LIKE('%Atenolol%')
            OR GenericName LIKE('%Atorvastatin%')
            OR GenericName LIKE('%Azithromycin%')
            OR GenericName LIKE('%Bicalutamide%')
            OR GenericName LIKE('%Bezafibrate%')
            OR GenericName LIKE('%Candesartan%')
            OR GenericName LIKE('%Carvedilol%')
            OR GenericName LIKE('%Celecoxib%')
            OR GenericName LIKE('%Ciclopirox%')
            OR GenericName LIKE('%Ciprofloxacin%')
            OR GenericName LIKE('%Citalopram%')
            OR GenericName LIKE('%Clopidogrel%')
            OR GenericName LIKE('%Colchicine%')
            OR GenericName LIKE('%Cyclobenzaprine%')
            OR GenericName LIKE('%Donepezil%')
            OR GenericName LIKE('%Duloxetine%')
            OR GenericName LIKE('%Escitalopram%')
            OR GenericName LIKE('%Ezetimibe%')
            OR GenericName LIKE('%Finasteride%')
            OR GenericName LIKE('%Fluoxetine%')
            OR GenericName LIKE('%Folic acid%')
            OR GenericName LIKE('%Gabapentin%')
            OR GenericName LIKE('%Hydralazine%')
            OR GenericName LIKE('%Indapamide%')
            OR GenericName LIKE('%Irbesartan%')
            OR GenericName LIKE('%Letrozole%')
            OR GenericName LIKE('%Levetiracetam%')
            OR GenericName LIKE('%Losartan%')
            OR GenericName LIKE('%Mercaptopurine%')
            OR GenericName LIKE('%Metformin%')
            OR GenericName LIKE('%Metoprolol%')
            OR GenericName LIKE('%Montelukast%')
            OR GenericName LIKE('%Moxifloxacin%')
            OR GenericName LIKE('%Mycophenolate%')
            OR GenericName LIKE('%Nystatin%')
            OR GenericName LIKE('%Olanzapine%')
            OR GenericName LIKE('%Omeprazole%')
            OR GenericName LIKE('%Ondansetron%')
            OR GenericName LIKE('%Oxcarbazepine%')
            OR GenericName LIKE('%Pantoprazole%')
            OR GenericName LIKE('%Paroxetine%')
            OR GenericName LIKE('%Pioglitazone%')
            OR GenericName LIKE('%Pravastatin%')
            OR GenericName LIKE('%Pregabalin%')
            OR GenericName LIKE('%Quetiapine%')
            OR GenericName LIKE('%Quinine%')
            OR GenericName LIKE('%Ramipril%')
            OR GenericName LIKE('%Risedronate%')
            OR GenericName LIKE('%Rizatriptan%')
            OR GenericName LIKE('%Ropinirole%')
            OR GenericName LIKE('%Rosuvastatin%')
            OR GenericName LIKE('%Sertraline%')
            OR GenericName LIKE('%Sildenafil%')
            OR GenericName LIKE('%Simvastatin%')
            OR GenericName LIKE('%Solifenacin%')
            OR GenericName LIKE('%Sotalol%')
            OR GenericName LIKE('%Tadalafil%')
            OR GenericName LIKE('%Terbinafine%')
            OR GenericName LIKE('%Topiramate%')
            OR GenericName LIKE('%Valacyclovir%')
            OR GenericName LIKE('%Vancomycin%')
            OR GenericName LIKE('%Vitamin D%')
            OR GenericName LIKE('%Zolmitriptan%')
            OR GenericName LIKE('%Zopiclone%')
        GROUP BY 
            GenericName,
            Strength
        ORDER BY STOCK DESC
    `).then(handleQueryResolve)
    // .then((records) => {
    //   console.log(records);
    //   return records.map(row => buildMapToField(row));
    // });
}